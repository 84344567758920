import React from "react";
import { Loan } from "./LoanCalculator";
import { filter, includes } from "lodash";

export interface EditableLoanTableProps {
  loans: Loan[];
  updateLoan: (ndx: number, loan: Loan) => void;
  deleteLoan: (ndx: number) => void;
}

export default class EditableLoanTable extends React.PureComponent<
  EditableLoanTableProps,
  {}
> {
  onChange = (ndx: number, loan: Loan, field: string) => {
    return (event: any) => {
      this.props.updateLoan(ndx, {
        ...loan,
        [field]:
          field === "name" ? event.target.value : Number(event.target.value),
      });
    };
  };

  render() {
    const duplicates = filter(
      this.props.loans.map((_) => _.name),
      (value, index, iteratee) => includes(iteratee, value, index + 1)
    );
    return (
      <table className="center">
        <tr>
          <th>Name</th>
          <th>Unpaid Principal</th>
          <th>Unpaid Interest</th>
          <th>Interest Rate</th>
          <th></th>
        </tr>
        {this.props.loans.map((_, ndx) => {
          return (
            <tr key={ndx}>
              <td>
                <input
                  className={duplicates.includes(_.name) ? "duplicateLoan" : ""}
                  type="text"
                  value={_.name}
                  onChange={this.onChange(ndx, _, "name")}
                />
                {duplicates.includes(_.name) ? (
                  <span className="tooltip">
                    &nbsp;&#x1F6C8;
                    <span className="tooltiptext">
                      Please don't use duplicate loan names!
                    </span>
                  </span>
                ) : (
                  ""
                )}
              </td>
              <td>
                $
                <input
                  className="Number-Input"
                  type="number"
                  value={_.principal}
                  onChange={this.onChange(ndx, _, "principal")}
                />
              </td>
              <td>
                $
                <input
                  className="Number-Input"
                  type="number"
                  value={_.interest}
                  onChange={this.onChange(ndx, _, "interest")}
                />
              </td>
              <td>
                <input
                  className="Number-Input"
                  type="number"
                  value={_.rate}
                  onChange={this.onChange(ndx, _, "rate")}
                />
                %
              </td>
              <td>
                <button onClick={() => this.props.deleteLoan(ndx)}>
                  Remove
                </button>
              </td>
            </tr>
          );
        })}
      </table>
    );
  }
}
