import React from "react";

const links: { url: string; name: string }[] = [
  {
    name: "NerdWallet - Which Student Loan to Pay Off First",
    url:
      "https://www.nerdwallet.com/article/loans/student-loans/which-student-loan-to-pay-off-first",
  },
  {
    name: "Money Under 30 - Which Loan Should You Pay Off First",
    url: "https://www.moneyunder30.com/which-loan-should-you-pay-off-first",
  },
  {
    name: "Bank Rate - Which Accounts Should You Pay Off First",
    url: "https://www.bankrate.com/finance/debt/which-accounts-pay-first.aspx",
  },
  {
    name: "Student Loan Hero - Which Loan Should I Pay Off First",
    url:
      "https://studentloanhero.com/featured/which-loan-should-i-pay-off-first/",
  },
  {
    name: "Dave Ramsey - How to Pay Off Debt",
    url: "https://www.daveramsey.com/blog/how-to-pay-off-debt",
  },
  {
    name: "The Balance - Determining the Order of Paying Off Your Debts",
    url:
      "https://www.thebalance.com/how-do-i-determine-the-order-of-paying-off-my-debts-2385992",
  },
];

export default class Resources extends React.PureComponent {
  render() {
    return (
      <div>
        <h1>Additional Resources</h1>
        <div className="resourceLinks">
          <ul>
            {links.map((_) => (
              <li>
                <a href={_.url}>{_.name}</a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}
