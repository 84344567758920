import React from "react";
import LoanRepayment from "./LoanRepayment";
import EditableLoanTable from "./EditableLoanTable";
import { sum } from "lodash";
import { formatCurrency } from "../util";

export interface LoanCalculatorProps {}

export interface Loan {
  name: string;
  principal: number;
  interest: number;
  rate: number;
}

export const getLoanAmount = (loan: Loan) => {
  return loan.principal + loan.interest;
};

export const getLoansAmount = (loans: Loan[]) => {
  return sum(loans.map(getLoanAmount));
};

interface State {
  loans: Loan[];
}

export const getMonthlyInterestAccrual = (loans: Loan[]) => {
  return loans
    .map((_) => _.principal * (_.rate / 1200))
    .reduce((prev, _) => prev + _, 0);
};

let names = [...Array(26)].map((_, code) => String.fromCharCode(code + 65));

const getLoanName = () => {
  const name = names.length ? `Loan ${names[0]}` : "My Loan";
  names = names.slice(1);
  return name;
};

export default class LoanCalculator extends React.PureComponent<
  LoanCalculatorProps,
  State
> {
  constructor(props: LoanCalculatorProps) {
    super(props);
    this.state = {
      loans: [],
    };
  }

  componentDidMount = () => {
    this.addLoan();
  };

  addLoan = () => {
    this.setState({
      loans: [
        ...this.state.loans,
        {
          name: getLoanName(),
          principal: 1000,
          interest: 0,
          rate: 5,
        },
      ],
    });
  };

  updateLoan = (ndx: number, loan: Loan) => {
    this.setState({
      loans: [
        ...this.state.loans.slice(0, ndx),
        loan,
        ...this.state.loans.slice(ndx + 1, this.state.loans.length),
      ],
    });
  };

  deleteLoan = (ndx: number) => {
    this.setState({
      loans: [
        ...this.state.loans.slice(0, ndx),
        ...this.state.loans.slice(ndx + 1, this.state.loans.length),
      ],
    });
  };

  render() {
    return (
      <div>
        <div>
          <h1>Loans</h1>
          <EditableLoanTable
            loans={this.state.loans}
            updateLoan={this.updateLoan}
            deleteLoan={this.deleteLoan}
          />
          <button
            type="button"
            className="addLoanButton"
            onClick={this.addLoan}
          >
            Add loan
          </button>
          <h4>
            Monthly interest accrual:{" "}
            {formatCurrency(getMonthlyInterestAccrual(this.state.loans))}
          </h4>
        </div>
        {!this.state.loans.length ? (
          <div />
        ) : (
          <div>
            <hr />
            <LoanRepayment loans={this.state.loans} />
          </div>
        )}
      </div>
    );
  }
}
