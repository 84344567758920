import React from "react";
import { formatCurrency } from "../util";
import { Loan } from "./LoanCalculator";

export interface LoanTableProps {
  loans: Loan[];
}

export default class LoanTable extends React.PureComponent<LoanTableProps, {}> {
  render() {
    return (
      <table className="center">
        <tr>
          <th>Name</th>
          <th>Unpaid Principal</th>
          <th>Unpaid Interest</th>
        </tr>
        {this.props.loans.map((_, ndx) => {
          return (
            <tr key={ndx}>
              <td>{_.name}</td>
              <td>{formatCurrency(_.principal)}</td>
              <td>{formatCurrency(_.interest)}</td>
            </tr>
          );
        })}
      </table>
    );
  }
}
