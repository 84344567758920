import React from "react";
import { isEqual, max, min, range } from "lodash";
import LoanTable from "./LoanTable";
import {
  Loan,
  getMonthlyInterestAccrual,
  getLoansAmount,
  getLoanAmount,
} from "./LoanCalculator";
import Graph from "./Graph";
import moment from "moment";
import Resources from "./Resources";
import { formatCurrency } from "../util";

export interface LoanRepaymentProps {
  loans: Loan[];
}

interface State {
  option: RepaymentOption;
  budget: number;
  singlePayment: boolean;
}

type MonthlyDetails = Array<{
  loans: Loan[];
  totalRemaining: number;
  totalPaid: number;
}>;

interface RepaymentSummary {
  description: string;
  totalPaid: number;
  numMonths: number;
  viable: boolean;
  months: MonthlyDetails;
}

type RepaymentOption =
  | "Snowball"
  | "Reverse Snowball"
  | "Highest Rate First"
  | "Highest Monthly Accrual First"
  | "Even Split";

const repaymentOptions: Record<
  RepaymentOption,
  { description: string; comparator: (a: Loan, b: Loan) => number }
> = {
  Snowball: {
    description: "Pay towards the loan with the smallest unpaid balance first.",
    comparator: (a, b) => a.principal + a.interest - (b.principal + b.interest),
  },
  "Reverse Snowball": {
    description: "Pay towards the loan with the largest unpaid balance first.",
    comparator: (a, b) => b.principal + b.interest - (a.principal + a.interest),
  },
  "Highest Rate First": {
    description: "Pay towards the loan with the highest interest rate first.",
    comparator: (a, b) => b.rate - a.rate,
  },
  "Highest Monthly Accrual First": {
    description:
      "Pay towards the loan that is contributing the most to your monthly interest accrual (unpaid balance multiplied by interest rate).",
    comparator: (a, b) =>
      (b.principal + b.interest) * b.rate - (a.principal + a.interest) * a.rate,
  },
  "Even Split": {
    description:
      "Split your payment evenly between each loan, regardless of unpaid balance or rate.",
    comparator: () => 0,
  },
};

const getRepaymentSummary = (
  loans: Loan[],
  budget: number,
  description: string,
  loanComparator: (a: Loan, b: Loan) => number
): RepaymentSummary => {
  const months: MonthlyDetails = [
    { loans, totalRemaining: getLoansAmount(loans), totalPaid: 0 },
  ];
  let numMonths = 0;
  while (
    numMonths++ < 50 * 12 &&
    getLoansAmount(months[months.length - 1].loans)
  ) {
    const lastMonth = months[months.length - 1];
    const loans = applyInterest(lastMonth.loans);
    const target = [...loans].sort(loanComparator)[0];
    const newLoans = makePayment(loans, target, budget);
    const totalRemaining = getLoansAmount(newLoans);
    months.push({
      loans: newLoans,
      totalRemaining,
      totalPaid: lastMonth.totalPaid + min([budget, lastMonth.totalRemaining])!,
    });
  }
  return {
    numMonths,
    months,
    viable: months[months.length - 1].totalRemaining === 0,
    description,
    totalPaid: months[months.length - 1].totalPaid,
  };
};

const getEvenSplitRepaymentSummary = (loans: Loan[], budget: number) => {
  const months: MonthlyDetails = [
    { loans, totalRemaining: getLoansAmount(loans), totalPaid: 0 },
  ];
  let numMonths = 0;
  while (
    numMonths++ < 50 * 12 &&
    getLoansAmount(months[months.length - 1].loans)
  ) {
    const lastMonth = months[months.length - 1];
    const loans = applyInterest(lastMonth.loans);
    const perLoanAmount = Math.floor(budget / loans.length);
    let newLoans = [...loans];
    loans.forEach((_) => {
      newLoans = makePayment(newLoans, _, perLoanAmount);
    });
    const totalRemaining = getLoansAmount(newLoans);
    months.push({
      loans: newLoans,
      totalRemaining,
      totalPaid: lastMonth.totalPaid + min([budget, lastMonth.totalRemaining])!,
    });
  }
  return {
    numMonths,
    months,
    viable: months[months.length - 1].totalRemaining === 0,
    description: `Split your monthly payment evenly between each loan.`,
    totalPaid: months[months.length - 1].totalPaid,
  };
};

const applyInterest = (loans: Loan[]) => {
  return loans.map((_) => ({
    ..._,
    interest: _.interest + _.principal * (_.rate / 1200),
  }));
};

const makePayment = (loans: Loan[], toPay: Loan, amount: number) => {
  return [
    ...loans.filter((_) => !isEqual(toPay, _)),
    {
      ...toPay,
      interest: max([0, toPay.interest - amount])!,
      principal: max([
        0,
        toPay.principal - max([0, amount - toPay.interest])!,
      ])!,
    },
  ].filter((_) => !!getLoanAmount(_));
};

const getRecurringPaymentDetails = (
  loans: Loan[],
  budget: number,
  option: RepaymentOption
) => {
  const summary =
    option === "Even Split"
      ? getEvenSplitRepaymentSummary(loans, budget)
      : getRepaymentSummary(
          loans,
          budget,
          repaymentOptions[option].description,
          repaymentOptions[option].comparator
        );
  const labels = range(0, summary.numMonths)
    .map((_) => `${_}`)
    .map((_) =>
      moment()
        .add(_, "months")
        .format("MM/YYYY")
    );
  return (
    <div>
      <p>
        You will pay off all of your loans in <b>{summary.numMonths}</b> months,
        paying a total of <b>{formatCurrency(summary.totalPaid)}</b>.
      </p>
      <Graph
        labels={labels}
        datasets={loans.map((loan) => {
          return {
            label: loan.name,
            data: summary.months.map((monthSummary) => {
              return getLoanAmount(
                monthSummary.loans.find((_) => loan.name === _.name) || {
                  principal: 0,
                  interest: 0,
                  name: "",
                  rate: 0,
                }
              );
            }),
          };
        })}
      />
      <Graph
        labels={labels}
        datasets={[
          {
            label: "All Loans",
            data: summary.months.map((_) => _.totalRemaining),
          },
        ]}
      />
    </div>
  );
};

export default class LoanRepayment extends React.PureComponent<
  LoanRepaymentProps,
  State
> {
  constructor(props: LoanRepaymentProps) {
    super(props);
    this.state = {
      option: "Snowball",
      budget: 100,
      singlePayment: true,
    };
  }

  updateRepaymentOption = (event: any) => {
    this.setState({
      option: event.target.value,
    });
  };

  setSingleOrRecurringPayment = (event: any) => {
    this.setState({
      singlePayment: event.target.value === "single",
    });
  };

  chooseSingleLoan = (): Loan | undefined => {
    if (!this.props.loans.length) {
      return;
    }
    return [...this.props.loans].sort(
      repaymentOptions[this.state.option].comparator
    )[0];
  };

  render() {
    const singleLoan = this.chooseSingleLoan();
    const newLoans = makePayment(
      this.props.loans,
      singleLoan!,
      this.state.budget
    );

    const newMonthlyAccrual = !singleLoan
      ? undefined
      : getMonthlyInterestAccrual(newLoans);
    const monthlyAccrual = getMonthlyInterestAccrual(this.props.loans);
    return (
      <div>
        <h1>Repayment</h1>
        <div>
          <div>
            <p>
              I can afford a{" "}
              <select
                name="singlePayment"
                id="singlePayment"
                onChange={this.setSingleOrRecurringPayment}
              >
                <option value="single" selected={true}>
                  single
                </option>
                <option value="monthly">monthly</option>
              </select>{" "}
              payment of $
              <input
                className="Number-Input"
                type="number"
                value={this.state.budget}
                onChange={(event) => {
                  this.setState({ budget: Number(event.target.value) });
                }}
              />
              , and I'd like to use the{" "}
              <select
                name="option"
                id="option"
                onChange={this.updateRepaymentOption}
              >
                {Object.keys(repaymentOptions)
                  .sort()
                  .map((_, ndx) => (
                    <option
                      key={ndx}
                      value={_}
                      selected={_ === this.state.option}
                    >
                      {_}
                    </option>
                  ))}
              </select>{" "}
              repayment strategy.
            </p>
            <b>What is the "{this.state.option}" strategy?</b>
            <p>{repaymentOptions[this.state.option].description}</p>
            <br />
          </div>
        </div>
        {this.props.loans.length ? (
          <div>
            {this.state.singlePayment ? (
              <div>
                <h3>
                  If you make a single (non-recurring) payment of $
                  {this.state.budget} using the "{this.state.option}" strategy,
                  do it to "{singleLoan!.name}"
                </h3>
                <p>
                  Your monthly interest accrual will drop to{" "}
                  <b>{formatCurrency(newMonthlyAccrual!)}</b> and your loans
                  will look like this:
                </p>
                <LoanTable loans={newLoans} />
                <br />
                <br />
              </div>
            ) : (
              <div>
                <h3>
                  If you make a recurring payment of ${this.state.budget} every
                  month using the "{this.state.option}" strategy, your progress
                  will look like this:
                </h3>
                {monthlyAccrual >= this.state.budget ? (
                  <p>
                    Unfortunately, with a monthly payment of $
                    {this.state.budget} your loans will&nbsp;
                    <b>never be paid off.</b> You will need to increase your
                    monthly payment to get out of debt.
                  </p>
                ) : (
                  getRecurringPaymentDetails(
                    this.props.loans,
                    this.state.budget,
                    this.state.option
                  )
                )}
              </div>
            )}
          </div>
        ) : (
          <div />
        )}
        <hr />
        <Resources />
      </div>
    );
  }
}
