import React from "react";
import { Line } from "react-chartjs-2";
import { formatCurrency } from "../util";

interface GraphProps {
  labels: string[];
  datasets: Dataset[];
}

interface Dataset {
  label: string;
  data: any[];
}

const targetNumberOfDataPoints = 24;

const colors = [
  "75,192,192",
  "153,153,255",
  "233,215,88",
  "41,115,115",
  "255,133,82",
  "69,67,114 ",
  "11,110,79",
  "197,123,87",
  "200,219,100",
  "129,193,75",
];

const reduceDataPoints = (data: any[], targetLength: number) => {
  if (data.length <= targetLength) {
    return data;
  }
  return data.filter(
    (_, ndx) => !(ndx % Math.ceil(data.length / targetLength))
  );
};

const generateDataSet = (dataset: Dataset, color: string) => {
  return {
    label: dataset.label,
    fill: false,
    lineTension: 0.1,
    display: true,
    backgroundColor: `rgba(${color},0.4)`,
    borderColor: `rgba(${color},1)`,
    borderCapStyle: "butt",
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: "miter",
    pointBorderColor: `rgba(${color},1)`,
    pointBackgroundColor: "#fff",
    pointBorderWidth: 1,
    pointHoverRadius: 5,
    pointHoverBackgroundColor: `rgba(${color},1)`,
    pointHoverBorderColor: `rgba(220,220,220,1)`,
    pointHoverBorderWidth: 2,
    pointRadius: 1,
    pointHitRadius: 10,
    data: reduceDataPoints(dataset.data, targetNumberOfDataPoints),
  };
};

export default class Graph extends React.PureComponent<GraphProps, {}> {
  render() {
    const data = {
      labels: reduceDataPoints(this.props.labels, targetNumberOfDataPoints),
      datasets: this.props.datasets.map((_, ndx: number) =>
        generateDataSet(_, colors[ndx])
      ),
    };
    return (
      <div>
        <div className="graph">
          <Line
            data={data}
            options={{
              maintainAspectRatio: false,
              scales: {
                yAxes: [
                  {
                    ticks: {
                      callback: (value: string) =>
                        formatCurrency(Number(value)),
                    },
                    scaleLabel: {
                      labelString: "Unpaid Balance & Unpaid Interest",
                      display: true,
                    },
                  },
                ],
                xAxes: [
                  {
                    scaleLabel: {
                      labelString: "Date",
                      display: true,
                    },
                  },
                ],
              },
            }}
          />
        </div>
      </div>
    );
  }
}
